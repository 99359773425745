export const setHighlightPeriod = ({highlightPeriod, scroll=false}, dispatch) => {

    return new Promise((resolve, reject) => {
        dispatch({
            type: "SET_HIGHLIGHT_PERIOD",
            highlightPeriod
        });
        if ( scroll ) {
            dispatch({
                type: "SCROLL_TO_PERIOD",
                scrollToPeriod: highlightPeriod
            });
        }
    })
};

export const setHighlightID = ({highlightID, scroll=false}, dispatch) => {

    return new Promise((resolve, reject) => {
        dispatch({
            type: "SET_HIGHLIGHT_ID",
            highlightID
        });
        if ( scroll ) {
            dispatch({
                type: "SCROLL_TO_ID",
                scrollToID: highlightID
            });
        }

    })
};

export const clearScrollTargets = ({}, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "CLEAR_SCROLL_TARGETS"
        });
    })
};

