import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {PageLayout} from "../components/PageLayout";

const styles = theme => ({
    root: {
        paddingTop: 16,
        paddingBottom: 16,
        background: "#333333",
        fontSize: 12,
        color: "#fff",
        position: "relative",
        zIndex: 10000,

        "& a": {
            color: "#fff"
        }
    },


});


export const _Footer = ({classes}) => {

    const curYear = new Date().toISOString().slice(0, 4)
    return (
        <div className={classes.root}>
            <PageLayout>

                <Grid container alignItems={"flex-start"} spacing={4}>
                    <Grid item xs={12} sm={6} md={4}>
                        <h3>About</h3>
                        <p>
                            2020 News<br/>
                            Was All So Bad, But Maybe<br/>
                            Less Bad as Haikus?
                        </p>
                        <p>
                            Everyday* in 2020, we posted the top news stories to Mechanical Turk,
                            asking turkers to respond with a 5, 7, 5 syllable haiku.
                            These are the results.
                        </p>

                        <p style={{marginBottom: 0}}>* almost</p>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <h3>Links</h3>
                        <p>The haiku dataset is available here: <a href="https://www.kaggle.com/newshaikus/dataset"
                                                                   target={"_blank"} rel="noreferrer">NewsHaikus
                            dataset</a>.</p>
                        <p>Code & design by: <a href="https://3isapattern.com">3 is a pattern</a>.</p>
                        <p>&#169; 3iap {curYear}</p>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <h3>Contact</h3>
                        <p>Please direct questions and comments to <a href="mailto:hi@3iap.co">hi@3iap.co</a>.</p>
                        <p>Inquiries in haiku form will be prioritized and replied to first.</p>
                    </Grid>
                </Grid>
            </PageLayout>
        </div>
    )
}

export const Footer = withStyles(styles)(_Footer)
export default Footer
