import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import HelpIcon from "@material-ui/icons/Help";
import Modal from '@material-ui/core/Modal';
import Paper from "@material-ui/core/Paper";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const _HelpModal = ({classes = {}}) => {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <IconButton size="small"
                        onClick={handleOpen}>
                <HelpIcon style={{fontSize: 13}} fontSize={"inherit"}/>
            </IconButton>
            <Modal
                disableEnforceFocus
                disableAutoFocus
                open={open}
                onClose={handleClose}>
                <div style={{width: 300, margin: "0 auto", marginTop: 32, fontSize: 12}}>
                    <Paper style={{padding: 16}}>
                        <h3>About the "Dread Line"</h3>
                        <p>
                            The dread line shows each haiku as a dot <FiberManualRecordIcon
                            style={{verticalAlign: "-2px"}} fontSize={"inherit"}/> plotted on a timeline.
                            Blue dots <FiberManualRecordIcon style={{verticalAlign: "-2px", color: "00476C"}}
                                                             fontSize={"inherit"}/> are good news (i.e. have positive
                            sentiment according to Google Natural Language API)
                            and red dots <FiberManualRecordIcon style={{verticalAlign: "-2px", color: "632500"}}
                                                                fontSize={"inherit"}/> are bad news.
                            The vertical position of the dot also corresponds to sentiment; higher dots are more
                            positive, lower dots are more negative.
                        </p>
                        <p>Large dots <FiberManualRecordIcon
                            style={{fontSize: 16, verticalAlign: "-4px", lineHeight: "12px"}}
                            fontSize={"inherit"}/> represent news stories that were more popular (based on the # of
                            upvotes on Reddit).
                            Small dots <FiberManualRecordIcon style={{fontSize: 8}} fontSize={"inherit"}/>) were less
                            popular.
                        </p>
                        <p>
                            You can click on the dread line to navigate to each month.
                            When you click a haiku's text, it'll highlight the haiku in the dread line.
                        </p>
                        <div style={{textAlign: "right"}}>
                            <Button variant="outlined" onClick={handleClose}>Close</Button>
                        </div>

                    </Paper>
                </div>
            </Modal>
        </div>

    )
}

export const HelpModal = _HelpModal
export default HelpModal
