import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {groupBy, fromPairs, toPairs, isNil, first, last} from "lodash"
import {HaikuDiv} from "../components/HaikuDiv";
import Store from "../Store";
import PageLayout from "../components/PageLayout";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import {clearScrollTargets, setHighlightPeriod} from "../actions/interaction";
import {debounce} from "../util/util";

dayjs.extend(utc)

const styles = theme => ({
    root: {
        marginTop: 40,
        [theme.breakpoints.up('sm')]: {
            // marginTop: 16
        },
        minHeight: 400
    },
    leftLabel: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        },
        textAlign: "right",
        marginRight: 16
    },
    centerLabel: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        },
        marginBottom: 16
    }

});

function getDocumentPosition(element) {
    const clientRect = element.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        left: clientRect.left + scrollLeft,
        top: clientRect.top + scrollTop
    };
}

const allTimePeriods = [
    "2020-01", "2020-02", "2020-03", "2020-04", "2020-05", "2020-06",
    "2020-07", "2020-08", "2020-09", "2020-10", "2020-11", "2020-12"]

const _Page = ({classes}) => {
    // console.log("Render Home")

    const {
        state: {
            haikus, matchingHaikuIDs, matchingHaikuIDsByTimePeriod,
            highlightPeriod, scrollToPeriod,
            highlightID, scrollToID
        }, dispatch
    } = useContext(Store);
    // const machingHaikus = matchingHaikuIDs.map(id=>haikus[id])

    // console.log(`scrollToPeriod: ${scrollToPeriod}`)
    // console.log(firstPeriod)


    // console.log(maxPeriodIndex)
    const [lastScrolledId, setLastScrolledId] = useState(null)
    const [lastScrolledPeriod, setLastScrolledPeriod] = useState(null)
    const scrollTimer = useRef(null)

    const [maxPeriodIndex, setMaxPeriodIndex] = useState(1)

    useEffect(()=>{
        // Reset maxPeriodIndex after search
        const hundredthHaikuDate = (haikus[matchingHaikuIDs[100]||last(matchingHaikuIDs)]||{}).tsMonth
        const lastPeriodFromVisible =  allTimePeriods.indexOf(hundredthHaikuDate)
        setMaxPeriodIndex(lastPeriodFromVisible)

    }, [matchingHaikuIDs])

    useEffect(()=>{
        // Expand maxPeriodIndex if we need to grow to scroll to something
        const hundredthHaikuDate = (haikus[matchingHaikuIDs[100]||last(matchingHaikuIDs)]||{}).tsMonth
        const lastPeriodFromVisible =  allTimePeriods.indexOf(hundredthHaikuDate)
        const scrollToHaikuIndex = allTimePeriods.indexOf((haikus[scrollToID]||{}).tsMonth)
        const scrollToPeriodIndex = allTimePeriods.indexOf(scrollToPeriod)
        // console.log(`Resizing: ${maxPeriodIndex} | ${lastPeriodFromVisible} | ${scrollToHaikuIndex} | ${scrollToPeriodIndex}`)

        setMaxPeriodIndex(Math.max(maxPeriodIndex, lastPeriodFromVisible, scrollToHaikuIndex, scrollToPeriodIndex, 1))
    }, [scrollToPeriod, scrollToID])


    const periodSectionRefs = useRef(fromPairs(allTimePeriods.map((period) => [period, React.createRef()])))
    const [sectionPositions, setSectionPositions] = useState({})


    const timePeriodsToRender = useMemo(() => {
        // console.log("changing time periods")
        return allTimePeriods
            .slice(0, maxPeriodIndex + 1)
            .filter(curPeriod => matchingHaikuIDsByTimePeriod[curPeriod])
    }, [haikus, matchingHaikuIDs, maxPeriodIndex])



    const onShowMore = () => {
        setMaxPeriodIndex(maxPeriodIndex + 1)
    }


    useEffect(() => {
        // console.log("ID Scroll Effect")
        const element = document.getElementById(scrollToID);
        const scrollToHaiku = haikus[scrollToID]
        if ( isNil(scrollToHaiku )) {
            return
        }
        if ( element ) {
            // console.log(`ID Scroll: ${scrollToID}`)

            clearTimeout(scrollTimer.current)
            scrollTimer.current = setTimeout(() => {
                const element = document.getElementById(scrollToID);
                element.scrollIntoView({block: 'center'})
                clearScrollTargets({}, dispatch)
            }, 50);
        }
    }, [matchingHaikuIDs, scrollToID, timePeriodsToRender])

    useEffect(() => {
        // console.log("Period Scroll Effect")
        // const scrollToSection = periodSectionRefs.current[scrollToPeriod]
        const scrollToSectionPosition = sectionPositions[scrollToPeriod]
        if ( isNil(scrollToSectionPosition) ) return

        if ( scrollToSectionPosition ) {
            // console.log(`Period Scroll: ${scrollToPeriod}`)
            // Triggers re-render?

            clearTimeout(scrollTimer.current)
            scrollTimer.current = setTimeout(() => {
                window.scrollTo({top: scrollToSectionPosition.top - 112})
                clearScrollTargets({}, dispatch)
            }, 50);
        }
    }, [matchingHaikuIDs, scrollToPeriod, timePeriodsToRender, sectionPositions])


    useEffect(() => {
        // console.log("Setting section positions")
        let tempSectionPositions
        tempSectionPositions = toPairs(periodSectionRefs.current).filter(([k, v]) => v.current)
        tempSectionPositions = tempSectionPositions.map(([k, v]) => ([k, getDocumentPosition(v.current)]))
        tempSectionPositions = fromPairs(tempSectionPositions)
        setSectionPositions(tempSectionPositions)
        // sectionPositions.current = tempSectionPositions
    }, [timePeriodsToRender])

    const handleScroll = debounce(() => {
        const yScrollPosition = window.scrollY
        const highlightDate = toPairs(sectionPositions).reduce((acc, [period, {top}]) => {
            if (top <= (yScrollPosition + 116)) {
                return period
            } else {
                return acc
            }
        }, null)


        if (yScrollPosition == 0) {
            setHighlightPeriod({highlightPeriod: null}, dispatch)
        } else if (highlightDate !== highlightPeriod) {
            setHighlightPeriod({highlightPeriod: highlightDate}, dispatch)
        }
    }, 10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll])

    return (
        <div className={classes.root}>
            {timePeriodsToRender.map(curTimePeriod => {

                const periodHaikus = matchingHaikuIDsByTimePeriod[curTimePeriod].map(id=>haikus[id])
                const sectionRef = periodSectionRefs.current[curTimePeriod]
                const leftChildren = (
                    <div className={classes.leftLabel}>
                        {curTimePeriod !== highlightPeriod ?
                            dayjs(curTimePeriod).utc(true).format("MMMM") : ""}
                    </div>)

                return (
                    <PageLayout key={curTimePeriod}
                                ref={sectionRef}
                                leftChildren={leftChildren}
                    >
                        <div className={classes.centerLabel}>
                            <h3>{dayjs(curTimePeriod).utc(true).format("MMMM")}</h3>
                        </div>

                        <div style={{
                            display: "flex", flexWrap: "wrap",
                            alignItems: "flex-start",
                            marginBottom: 16
                        }}>
                            {periodHaikus.map((haiku) => {
                                return (<HaikuDiv haiku={haiku} key={haiku.id}
                                                  isOpen={highlightID === haiku.id}
                                                  dispatch={dispatch}
                                                  style={{flex: "0 0"}}/>)
                            })}
                        </div>

                    </PageLayout>)
            })}

            <br/>
            <PageLayout>
                {maxPeriodIndex < allTimePeriods.length-1 ? (
                    <Button variant="outlined" onClick={onShowMore}>Show More</Button>) : ""}
            </PageLayout>


        </div>

    )
}

export const Page = withStyles(styles)(React.memo(_Page))
export default Page

