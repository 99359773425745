import React, {useContext, useEffect, useMemo, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DreadLine from "../components/DreadLine2";
import SearchBox from "../components/SearchBox";
import TopicSelector from "../components/TopicSelector";
import Store from "../Store";
import PageLayout from "../components/PageLayout";
import dayjs from "dayjs";
import {setHighlightID, setHighlightPeriod} from "../actions/interaction";
import {debounce} from "../util/util";
import HelpModal from "../components/HelpModal";
import {isEmpty} from "lodash"

const styles = theme => ({
    root: {},

    siteTitle: {
        marginBottom: 0,
        lineHeight: 1.2,

        "& a": {
            color: "inherit",
            textDecoration: "none",
        }
    },

    titleRow: {
        paddingBottom: 8,
        background: "#ffffff",
        zIndex: 100
    },
    searchRow: {
        marginTop: 88,
        [theme.breakpoints.down('xs')]: {
            marginTop: 152,
        }

    },
    leftLabel: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },

    helpButton: {
        marginTop: 32,
        [theme.breakpoints.down('xs')]: {
            marginTop: 88,
        }
    },

    showSMUp: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },

    hideXSDown: {
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },

});


export const _Header = ({classes}) => {

    const {state: {haikus, matchingHaikuIDs, highlightPeriod, highlightID, indices}, dispatch} = useContext(Store);
    const [isTopPosition, setIsTopPosition] = useState(window.scrollY === 0)

    const matchingHaikus = useMemo(() => {
        return matchingHaikuIDs.map(id => haikus[id])
    }, [matchingHaikuIDs])

    const handleScroll = debounce(() => {
        const yScrollPosition = window.scrollY

        setIsTopPosition(yScrollPosition === 0)

    }, 10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll])

    const leftSide = (<div className={classes.leftLabel}
                           style={{
                               paddingTop: 88,
                               backgroundColor: "#ffffff",
                               textAlign: "right",
                               marginRight: 16
                           }}>
        {highlightPeriod ? dayjs(highlightPeriod).utc(true).format("MMMM") : ""}
    </div>)

    const onTitleClick = (event) => {
        event.stopPropagation();
        setHighlightID({highlightID: null}, dispatch)
        window.scrollTo({top: 0})
    }

    return (
        <div className={classes.root}>
            <div style={{
                position: "fixed",
                top: 0,
                width: "100%",
                zIndex: 100
            }}>
                <PageLayout leftChildren={leftSide}
                            rightChildren={<div className={classes.helpButton}><HelpModal/></div>}>

                    <div className={classes.titleRow}>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={12} sm={5}>
                                <h2 className={classes.siteTitle}>
                                    <a style={{cursor: "pointer"}}
                                       onClick={onTitleClick}>Doom Haikus</a></h2>
                                {isTopPosition ? <span>News Haikus from 2020</span> : ""}
                                {!isTopPosition ? <span><a style={{cursor: "pointer", color: "#00476C", fontSize: 12}} onClick={onTitleClick}>Back to top.</a></span> : ""}
                            </Grid>

                            <Grid item xs={12} sm={7}>
                                <div className={classes.hideXSDown}>
                                    <DreadLine haikus={matchingHaikus}
                                               height={56}
                                               {...{highlightPeriod, highlightID, dispatch}}/>
                                </div>
                                <div className={classes.showSMUp}>
                                    <DreadLine haikus={matchingHaikus}
                                               height={72}
                                               {...{highlightPeriod, highlightID, dispatch}}/>
                                </div>

                            </Grid>
                        </Grid>

                    </div>

                </PageLayout>

            </div>
            <PageLayout className={classes.root}>
                <div className={classes.searchRow}>
                    <Grid container spacing={4} alignItems={"flex-end"}>
                        <Grid item xs={12} sm={6}>
                            <SearchBox disabled={isEmpty(indices)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TopicSelector disabled={isEmpty(indices)}/>
                        </Grid>
                    </Grid>
                </div>


            </PageLayout>
        </div>
    )
}

export const Header = withStyles(styles)(_Header)
export default Header
