import {first, isNil, min, max} from "lodash";
import * as d3Scale from 'd3-scale'
import React, {useRef, useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import {setHighlightPeriod} from "../actions/interaction";
import {useDimensions} from "../hooks";
dayjs.extend(utc)

const styles = theme => ({
    root: {},

});

export const _DreadLine = ({haikus, height= 48, highlightID, highlightPeriod, dispatch}) => {

    // console.log("Dreadline Render")
    // console.log(`highlightID: ${highlightID}`)

    const containerRef = useRef();
    let {width} = useDimensions(containerRef)
    width = width * 2
    height = height * 2


    const points = haikus.map(h => ({
        x: Date.parse(h.tsPosted),
        y: h.sentiment.score * h.sentiment.magnitude,
        title: h.content,
        sentiment: h.sentiment.score,
        score: h.score,
        source: h.articleUrl,
        id: h.id,
        tsMonth: h.tsMonth
    }))

    // console.log(max(points.map(p=>p.y)))
    // console.log(min(points.map(p=>p.y)))

    const margin= {
        top: 0,
        bottom: 0,
        left: 4,
        right: 4}
    const xValues = points.map(p =>p.x)
    const xScale = d3Scale.scaleLinear()
        .domain([Date.parse("2020-01-01"), Date.parse("2021-01-01")])
        .range([margin.left, width-margin.right])
        // .nice()

    const xScaleAxis = d3Scale.scaleUtc()
        .domain([dayjs("2020-01-01").utc(true).toDate(), dayjs("2021-01-01").utc(true).toDate()])
        .range([margin.left, width-margin.right])
    .nice()

    const yScale = d3Scale.scaleLinear()
        .domain([-1.5,1])
        .range([height-margin.bottom, margin.top])
        .clamp(true)


    const rScale = d3Scale.scaleLinear()
        .domain([100, 1 * 4 * 100000])
        .range([1, 16])
        .clamp(false)

    const bgColorScale = d3Scale
        .scaleLinear()
        // .domain([yValues[20], 0, yValues[yValues.length-20]])
        .domain([-1, 1])

        // .range(["#FD5F00","#19678F"]);
.range(["#632500", "#00476C"]);
        // .range(["#632500", "#00476C"]);
// .range(["#000000", "#00476C"]);
// .range(["#333333", "#FAB2C4"]);

    const canvasRef = useRef(null)



    useEffect(() => {

        const draw = ctx => {
            ctx.clearRect(0,0, width, height)

            ctx.save()
            ctx.font = "20px Poppins"

            xScaleAxis.ticks(11).slice(0, 12).forEach(x => {
                ctx.fillStyle = "#656565"
                ctx.fillText(dayjs(x).utc(false).format("MMM"), xScaleAxis(x), height-12)
            })

            ctx.restore()

            ctx.save()
            points.forEach((p, index) => {
                ctx.fillStyle = (isNil(highlightPeriod) || p.tsMonth === highlightPeriod) ? bgColorScale(p.sentiment) : "#aaaaaa"
                ctx.globalAlpha = 0.6;
                ctx.beginPath()
                ctx.arc(xScale(p.x), yScale(p.y),
                    Math.max(0, rScale(Math.abs(p.score))),
                    0, 2 * Math.PI)
                ctx.fill()
            })
            ctx.restore()

            let p = first(points.filter(p=>p.id === highlightID))

            if (p ) {
                ctx.save()
                ctx.fillStyle = bgColorScale(p.sentiment)
                ctx.globalAlpha = 0.5;
                ctx.beginPath()
                ctx.arc(xScale(p.x), yScale(p.y),
                    16,
                    0, 2 * Math.PI)
                ctx.fill()

                ctx.fillStyle = "#ffffff"
                ctx.globalAlpha = 1;
                ctx.beginPath()
                ctx.arc(xScale(p.x), yScale(p.y),
                    Math.max(0, rScale(Math.abs(p.score))),
                    0, 2 * Math.PI)
                ctx.fill()
                ctx.restore()
            }

        }

        const canvas = canvasRef.current
        const context = canvas.getContext('2d')

        //Our draw come here
        draw(context)
    })

    const onClick=(event)=> {
        const currentTargetRect = event.target.getBoundingClientRect();
        const eventOffsetX = 2 * (event.pageX - currentTargetRect.left);
        const eventOffsetY = event.pageY - currentTargetRect.top;
        const clickDate = xScaleAxis.invert(eventOffsetX)
        const newHighlightPeriod = dayjs(clickDate).utc(false).format("YYYY-MM")
        setHighlightPeriod({highlightPeriod: newHighlightPeriod, scroll: true}, dispatch)
    }

    return (
        <div ref={containerRef} style={{
            position: "relative",
            height: height * 0.5 + "px",
            cursor: "pointer"}}>
            <canvas ref={canvasRef} width={width} height={height}
                    onClick={onClick}
                    style={{width: width * 0.5, height: height * 0.5,
                        position: "absolute", top: 0, left: 0}}
            />
           </div>
    )

}

export const DreadLine = withStyles(styles)(React.memo(_DreadLine, (prev,next)=>{
    return prev.haikus === next.haikus
        && prev.highlightID == next.highlightID
        && prev.highlightPeriod == next.highlightPeriod
}))
export default DreadLine
