import {isEmpty, first} from "lodash";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FilterIcon from '@material-ui/icons/FilterList'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {categoryConfig} from "../categoryConfig";
import {locationToPath} from "../util/util";
import {useHistory, useLocation} from "react-router-dom";
import {getURLParts, urlParamsToString} from "../util/util";

const styles = theme => ({
    root: {},
});


export const _TopicSelector = ({classes, disabled}) => {
    let history = useHistory();
    const location = useLocation()
    const urlParams = (getURLParts(window.location.href).searchParams || {})
    const topics = (urlParams.topics || "").split(",").filter(s=>!isEmpty(s))

    const handleChange = (event, topic) => {
        const searchString = urlParamsToString({...urlParams, topics: [topic].filter(s=>!isEmpty(s))})
        history.replace(locationToPath({...location, search: "?" + searchString}))
    };

    const themeIDs = [,
        "",
        "badnews",
        "goodnews",
        "covid",
        "election",
        "trump",
        "republicans",
        "socialists",
        "democrats",
        "supremeCourt",
        "race",
        "misinformation",
        "oligarchs",
        "weed",
        "climate",
        "foreignThreats",
    ]

    return (
        <Autocomplete
            style={{cursor: "pointer"}}
            id="combo-box-demo"
            value={first(topics) || ""}
            fullWidth
            disabled={disabled}
            onChange={handleChange}
            options={themeIDs}
            getOptionLabel={(option) => {
                return (!isEmpty(option) ? categoryConfig[option].label : "All Themes")
            }}
            renderInput={(params) => (
                <TextField ref={params.InputProps.ref}
                           fullWidth
                           style={{cursor: "pointer"}}
                           inputProps={{style: {cursor: "pointer"}}}
                           InputProps={{
                               ...params.inputProps,
                               style: {cursor: "pointer"},
                               startAdornment: (
                                   <InputAdornment position="end">
                                       <FilterIcon/>
                                   </InputAdornment>),
                               // endAdornment: (
                               //     <InputAdornment position="end">
                               //         {isEmpty(topics) ? "" : <CloseIcon fontSize="small"/>}
                               //     </InputAdornment>)
            }}
                           placeholder="Themes"
                />)}
        />)
}

export const TopicSelector = withStyles(styles)(_TopicSelector)
export default TopicSelector
