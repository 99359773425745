import React, {useContext, useEffect} from 'react';
import './App.css';
import HomePage from "./pages/Home";
import Header from "./pages/Header";
import Store from "./Store";
import * as haikuActions from "./actions/haikus";
import * as interactionActions from "./actions/interaction";
import {useLocation, useHistory} from "react-router-dom";
import {getURLParts, urlParamsToString} from "./util/util";
import {isEmpty} from "lodash"
import Footer from "./pages/Footer";


function App() {

    if (window.history && window.history.scrollRestoration) {
        window.history.scrollRestoration = 'manual';
    }

    const { state: {searchString, topics, highlightID, scrollToPeriod}, dispatch } = useContext(Store);
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        // Only on mount
        haikuActions.loadAllHaikus({}, dispatch)
            .then(() => {
                // Should come after haikus are loaded
                haikuActions.buildIndices({}, dispatch);
            })
            .then(() => {
                const scrollToId = location.hash.slice(1)
                interactionActions.setHighlightID({highlightID: scrollToId, scroll: true}, dispatch)
            })
    }, [])

    useEffect(()=>{
        const urlParams = (getURLParts(window.location.href).searchParams || {})
        const urlSearchQuery = (urlParams.q || "")
        const urlTopics = (urlParams.topics || "").split(",").filter(s=>!isEmpty(s))
        if ( urlSearchQuery !== searchString || urlTopics.join("") !== topics.join("") ) {
            haikuActions.filterHaikus({searchString: urlSearchQuery, topics: urlTopics}, dispatch)
        }
    }, [location])

    useEffect(()=>{
        // Update the URL hash to match existing state
        const urlParams = (getURLParts(window.location.href).searchParams || {})
        let newPath = "/?" + urlParamsToString(urlParams)
        if ( highlightID ) {
            newPath = newPath + "#" + highlightID
        }
        history.replace(newPath)
    }, [highlightID])


    return (
    <div className="App">
        <Header/>
        <HomePage/>
        <br/><br/><br/>
        <Footer/>
    </div>
  );
}

export default App;
