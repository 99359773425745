export const categoryConfig = {
    trump: {
        label: "Trump",
        keywords: ["trump", "kushner", "ivanka", ["orange", "menace"], "impeach"],
        mids: [
            "/m/0cqt90", //Trump
            "/m/0fv_sp", //"jared kushner"
            "/m/04hfnv", //"melania trump",
            "/m/061wfm", //"ivanka",
            "/m/0dzqp5" //"don jr.",
        ]
    },
    election: {
        label: "2020 U.S. Election",
        keywords: [
            "elect",
            "voter",
            "campaign",
            "poll",
            "ballot",
            "primari",
            "primary",
            "usps",
            ["voter", "fraud"],
            ["earli", "vote"],
            ["postal", "servic"]
        ],
        mids: [
            "/m/0dcms" //"us postal service"
        ]
    },
    covid: {
        label: "Covid-19",
        keywords: [
            "coronaviru",
            "covid",
            "corona",
            "pandem",
            "viru",
            "mask",
            "cdc",
            "fauci",
            "hydroxychloroquin",
            "vacine",
            ["stay", "at", "home"],
            "quarantine",
            ["medic", "shortag"],
            "ppe"
        ],
        mids: [
            "/g/11j2cc_qll", //covid
            "/m/01zrs", //cdc
            "/m/061s4", // Global pandemic
            "/m/0l3cy" // Wuhan?
        ]
    },
    race: {
        label: "Racism & Unrest",
        keywords: [
            "blm",
            "breonna",
            "ahmaud",
            "arbery",
            "philando",
            "castille",
            ["michael", "brown", "ferguson"],
            ["stephon", "clark", "sacramento"],
            ["alton", "sterling"],
            ["eric", "garner"],
            ["tamir", "rice"],
            ["kenosha", "shoot"],
            ["georg", "floyd"],
            ["black", "live"],
            ["blue", "lives"],
            ["defund", "polic"],
            // "protest",
            "rittenhouse",
            "kkk",
            ["portland", "polic"],
            ["portland", "feder", "agent"],
            ["polic", "tampon"],
            ["tear", "ga"],
            ["pepper", "sprai"],
            ["choke", "hold"],
            "chokehold",
            ["body", "cam"],
            "bodycam",
            ["domestic", "terror"],
            "antifa",
            ["anti", "fascist"],
            ["white", "nationalist"],
            "confederate",
            "confederaci",
            "confeder",
            "kenosha",
            ["polic", "shoot"],
            ["polic", "shot"],
            ["cop", "shoot"],
            ["cop", "shot"],
            ["trooper", "shoot"],
            ["trooper", "shot"],
            ["neo", "nazi"],
            "militia",
            ["proud", "boi"],
            ["racial", "inequ"],
            ["white", "supremacist"],
            ["ic", "whistleblow"],
            ["polic", "black"],
            ["polic", "brutal"],
            ["polic", "violenc"],
            ["polic", "protest"],
            ["ami", "cooper"],
            ["polic", "chief"],
            "nypd", "lapd", "spd",
            "racism",
            ["buffalo", "police"],
            ["seattl", "police"],
            ["seattl", "protest"],
            ["portland", "police"],
            ["portland", "protest"],
            ["ronnie", "long"],
            ["cleveland", "cop"],
            ["san", "jose", "cop"]

        ],
        mids: [
            "/g/11hfncjgzh", //      "breonna taylor",
            "/m/02qxc9m", //      "george floyd",
            "/m/012hdhkj", //"blm",
            "/g/11g6rk4qnn", //      "proud boys",
            "/m/020d5", //      "confederacy",
            "/g/11fv4g_5th", //      "ahmaud",
            "/m/048qr", //      "kkk",
            "/m/0x67", //      "african-american",
            "/m/02555z", //      "john lewis",
            "/g/11lg2_6mfn", //      "kyle rittenhouse",
            "/m/05ws7", //      "police",
            "/m/0x1xf1p", //      "border wall",
            "/g/11f01zgsnf", //      "unite the right",
            "/m/02h98sm" //      "kenosha",
        ]
    },

    supremeCourt: {
        label: "U.S. Supreme Court",
        keywords: [
            ["suprem", "court"],
            "scotus",
            "ginsburg",
            "rbg",
            ["ruth", "bader"],
            "alito",
            ["merrick", "garland"],
            "barrett",
            ["amy", "coney"],
            "kavanaugh"[("john", "roberts")],
            ["pack", "court"],
            ["feder", "judge"]
        ],
        mids: [
            "/m/0199pk", //      "ruth bader ginsburg",
            "/m/07sz1", //      "supreme court",
            "/g/11dyxzkl3v", //      "amy coney",
            "/m/04gnxk", //      "samuel alito",
            "/m/0hx5v" //      "roe v. wade",
        ]
    },

    misinformation: {
        label: "Misinformation",
        keywords: [
            ["qanon"],
            "conspiracy",
            ["conspiracy", "theorist"],
            ["tucker", "carlson"],
            ["hannity"],
            ["fox", "news"],
            "hydroxychloroquin",
            "propaganda",
            "misinform",
            "disinform",
            "twitter",
            "facebook",
            ["voter", "fraud"],
            "holocaust",
            "limbaugh",
            ["ben", "shapiro"],
            ["dr", "oz"]
        ],

        mids: [
            "/m/02z_b", //"fox news",
            "/m/0gsgr", //"cnn",
            "/m/08193", //"putin",
            "/m/086dny", //"zuckerberg",
            "/m/01v054", //"sean hannity",
            "/m/0152x_", //"msnbc",
            "/g/11hc_gvb_r", //"qanon",
            "/m/03cqm2" //"tucker carlson",
        ]
    },

    oligarchs: {
        label: "The Oligarchy",
        keywords: [
            "bezos",
            "amazon",
            "bloomberg",
            "epstein",
            "putin",
            "zuckerberg",
            "zuck",
            "facebook",
            "google",
            ["elon", "musk"],
            "tesla",
            "weinstein",
            "koch",
            "perdu",
            "loeffler",
            "chamath",
            ["insid", "trade"],
            ["dow", "jones"],
            "billionair",
            "landlord"
        ],

        mids: [
            "/m/011z69", //      Bezos,
            "/m/0mgkg", //"amazon",
            "/m/027sm6", //"bloomberg",
            "/m/0fz196", //"epstein",
            "/m/0841v", //"walmart",
            "/m/08193", //"putin",
            "/m/018c_r", //"starbucks",
            "/m/086dny", //"zuckerberg",
            "/m/050fvg", //"hobby lobby",
            "/m/0gx0wlr", //"uber",
            "/m/017nt", //"bill gates",
            "/m/0dr90d" //"tesla",
        ]
    },

    foreignThreats: {
        label: "Elsewhere...",
        keywords: [
            "iran",
            "iraq",
            "isis",
            "russia",
            "russian",
            "putin",
            "china",
            "xi",
            "afghanistan",
            "afghan"
        ],

        mids: [
            "/m/03shp", //      "iran",
            "/m/08193", //      "putin",
            "/m/0g6ff" //      "russians",
        ]
    },
    republicans: {
        label: "Trump Enablers",
        keywords: [
            // “majority leader”
            "pence",
            "barr",
            "mcconnell",
            "mcconnel",
            "guiliani",
            "graham",
            "collins",
            "loeffler",
            "pompeo",
            "flynn",
            ["roger", "stone"],
            "devos",
            "dejoi",
            "purdue",
            "barrett",
            "mattis",
            ["rand", "paul"],
            "sasse",
            "pascrell",
            "mcsalli",
            "murkowski",
            ["stephen", "miller"],
            "bannon",
            "gaetz"
        ],

        mids: [
            "/m/07wbk", //"g.o.p.",
            "/m/022r9r", //"mike pence",
            "/m/027mccx", //"william barr",
            "/g/11f0yp0nhr", //"luis dejoy",
            "/m/01z6ls", //"mitch mcconnell",
            "/m/0271_s", //"romney",
            "/m/06gn7", //"guiliani",
            "/m/01_pdg", //"graham",
            "/m/020y8m", //"susan collins",
            "/g/11j3kgpjlx", //"kelly loeffler",
            "/m/0cnxjk7", //"mike pompeo",
            "/g/11cjj561tp", //"mike cohen",
            "/m/09v12g0", //"flynn",
            "/m/05r8b8", //"roger stone",
            "/m/06y4wt", //"betsy devos",
            "/m/03xv5n", //"john bolton",
            "/m/03wp8v4", //"david perdue",
            "/g/11dyxzkl3v", //"amy coney",
            "/m/0bymv", //"mccain",
            "/m/05419_", //"mattis",
            "/m/0k2l4q1", //"mcenany",
            "/m/05pdb7q", //"rand paul",
            "/m/0j5wk_6", //“ben sasse",
            "/m/03txs_", //"pascrell",
            "/m/027fybv", //"martha mcsally",
            "/g/11c138krr4", //"stephen miller",
            "/m/0202kt", //"lisa murkowski",
            "/m/0bqscsg" //"steve bannon",
        ]
    },

    democrats: {
        label: "Not Enough Socialism",
        keywords: [
            "biden",
            "obama",
            "pelosi",
            "schumer",
            "buttigieg",
            ["stacei", "abram"],
            ["roi", "cooper"]
        ],

        mids: [
            "/m/012gx2", //"biden",
            "/m/02mjmr", //      "barack",
            "/m/012v1t", //"nancy pelosi",
            "/m/08sry2", //      "harris",
            "/m/02pjpd", //"andrew cuomo",
            "/g/11c2j1tl6v", //"jaime harrison",
            "/m/03c469", //"clyburn",
            "/m/025s5v9", //"michelle obama",
            "/m/01w74d", //"schumer",
            "/g/11g6njlbw2", //"ossoff",
            "/m/02wsxy", //"mark kelly",
            "/m/0cf5_4" //"whitmer",
        ]
    },

    socialists: {
        label: "Too Much Socialism",
        keywords: [
            "bernie",
            "berni",
            "sander",
            "sanders",
            "warren",
            ["andrew", "yang"],
            "aoc",
            ["ocasio", "cortez"]
        ],

        mids: [
            "/m/01_gbv", //"bernie sanders",
            "/g/11f6y3nqg6" //"alexandria cortez",
        ]
    },
    weed: {
        label: "Reefer Madness",
        keywords: [
            "weed",
            "marijuana",
            "pot",
            "cannabi"

        ],

        mids: [

        ]
    },
    climate: {
        label: "Climate Change",
        keywords: [
            "wildfir",
            ["wild", "fire"],
            "climat",
            "hurrican",
            "forest",
            ["tree", "bulldoz"]

        ],

        mids: [

        ]
    },
    badnews: {
        label: "Bad News",
        keywords: [],
        mids: []
    },
    goodnews: {
        label: "Silver Linings",
        keywords: [],
        mids: []
    }

}
export default categoryConfig