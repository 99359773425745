import {isEmpty, fromPairs, toPairs} from "lodash"

export const locationToPath = (location) => {
    return `${location.pathname}${location.search}${location.hash}`
}

export const getURLParts = (url) => {
    if (isEmpty(url)) {
        return null
    }
    let urlObj
    try {
        urlObj = new URL(url)
    } catch (ex) {
        return null
    }

    let output = {
        hash: urlObj.hash,
        host: urlObj.host,
        hostname: urlObj.hostname,
        href: urlObj.href,
        origin: urlObj.origin,
        password: urlObj.password,
        pathname: urlObj.pathname,
        port: urlObj.port,
        protocol: urlObj.protocol,
        search: urlObj.search,
        searchParams: fromPairs([...urlObj.searchParams.entries()]),
        username: urlObj.username
    }
    return output
}
// window.getURLParts=getURLParts
// getURLParts(null)
// getURLParts("")
// getURLParts("http://localhost:3000/?foo=bar&empty=")


export const urlParamsToString = (obj) => {
    if (isEmpty(obj)) {
        return ""
    }
    obj = fromPairs(toPairs(obj).filter(([k, v]) => !isEmpty(v)))
    return new URLSearchParams(obj).toString()
}

export const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
        const later = () => {
            timeout = null;
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};