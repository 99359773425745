import React, {useState} from "react";
import * as d3Scale from 'd3-scale'
import './HaikuDiv.css'
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip";
import {setHighlightID} from "../actions/interaction";
import {truncate} from "lodash"
import {getURLParts} from "../util/util";

const fontScale = d3Scale
    .scaleThreshold()
    .domain([60000])
    // .range([12, 12]);
    .range([12, 14]);

const bgColorScale = d3Scale
    .scaleLinear()
    .domain([-2, -.1, .1, 1])
    .range(["#63250044", "#63250000", "#00476C00", "#00476C55"])
    .clamp(true)


export const _HaikuDiv = ({haiku, isOpen, dispatch}) => {
    // if (haiku.id === "4tDEJFBrtXeyyMyUHxix5Q") {
    //     console.log("HaikuDiv Render ")
    // }
    // if ( haiku.id === "eHj7s2gLKX6_W667EMSJ5w") {
    //     console.log("HaikuDiv Render")
    // }
    const fontWeight = (60000 < haiku.score) ? 600 : 400
    const fontSize = fontScale(haiku.score);
    const lineHeight = fontSize * 1.2;
    const bgColor = bgColorScale(haiku.sentiment.score * haiku.sentiment.magnitude)
    const opacity = 1

    const haikuLines = haiku.content.split(/\n/g)
    let maxLineLength = haiku.maxLineLength
    maxLineLength += lineHeight
    maxLineLength = maxLineLength * (fontSize / 10)

    // const roundWidthToNearest = 1.5 * lineHeight
    const roundWidthToNearest = 80
    const adjustedWidth = Math.ceil(maxLineLength / roundWidthToNearest) * roundWidthToNearest

    const [copyToolTipText, setCopyToolTipText] = useState("Copy")

    const handleClick = () => {
        setHighlightID({highlightID: !isOpen ? haiku.id : null}, dispatch)
    }

    const handleCopy = (event) => {
        event.stopPropagation();
        navigator.clipboard.writeText(haiku.content + "\n" + "https://doomhaikus.3iap.co/#" + haiku.id)
        setCopyToolTipText("Copied!")
        setTimeout(() => {
            setCopyToolTipText("Copy")
        }, 1000)
    }

    const articleContent = truncate(haiku.title + " || " + haiku.description, {length: 200, omission: "..."})

    const openedStyle = {
        borderTop: "0.5px solid #aaa",
        borderBottom: "0.5px solid #aaa",
        paddingTop: lineHeight,
        paddingBottom: lineHeight,
        background: "#fbfbfb"
    }

    return (<div
        onClick={handleClick}
        id={haiku.id}
        data-sentiment={haiku.sentiment.score}
        data-sentiment-magnitude={haiku.sentiment.magnitude}
        data-haiku-id={haiku.id}
        className={"haikuContainer"}
        style={{
            width: `${adjustedWidth - lineHeight * 0.5}px`,
            marginRight: lineHeight * 0.5,
            marginBottom: lineHeight,
            fontWeight: fontWeight,
            cursor: "pointer",
            ...(isOpen ? openedStyle : {})
        }}>


        {haikuLines.map((curLine, index) => {
            return (
                <div key={index} style={{lineHeight: lineHeight + "px", opacity: opacity}}>
                    <div className={"haikuLineInner"} style={{
                        fontSize: fontSize,
                        backgroundColor: bgColor
                    }}>
                        {curLine}
                    </div>

                </div>)
        })}

        {isOpen ?
            (<div style={{fontSize: 10, textAlign: "left", marginTop: 4}}>
                <p style={{fontStyle: "italic", fontWeight: "normal", lineBreak: "break-word"}}>
                    <span>{articleContent} </span>
                    <span>
                        <a href={haiku.articleUrl} target="_blank">
                            {getURLParts(haiku.articleUrl).hostname.replace("www\.", "")}
                        </a>
                    </span>
                </p>
                <Tooltip title={copyToolTipText}>
                    <IconButton size="small" onClick={handleCopy}>
                        <CopyIcon fontSize="inherit"/>
                    </IconButton>
                </Tooltip>
            </div>)
            : ""
        }


    </div>)
}

export const HaikuDiv = React.memo(_HaikuDiv, (prev, next) => {
    return prev.haiku.id === next.haiku.id && prev.isOpen === next.isOpen
})
export default HaikuDiv
