import React from "react";
import {max, keyBy, sortBy, isNil, groupBy, mapValues, keys} from "lodash"
import * as textUtil from "./util/text";
import categoryConfig from "./categoryConfig";
import {buildIndices, search} from "./util/search";

export const Store = React.createContext("");
export default Store

const initialState = {
    haikus: {},
    matchingHaikuIDs: [],
    matchingHaikuIDsByTimePeriod: {},
    searchString: "",
    topics: [],
    indices: {}
};


const loadHaikus = (state, action) => {

    const {haikus, searchString, topics} = state

    let newHaikus = action.haikus
    // Pre-Calculate the width of the haiku
    newHaikus = newHaikus.map(h => {
        const haikuLines = h.content.split(/\n/)
        let maxLineLength = max(haikuLines.map(textUtil.stringWidth))
        return {...h, maxLineLength}
    })
    // Pre-calculate the month
    newHaikus = newHaikus.map(h => {
        return {...h, tsMonth: h.tsPosted.slice(0,7)}
    })
    newHaikus = keyBy(newHaikus, h => h.id)

    // console.log(newHaikus)
    newHaikus = {...newHaikus, ...haikus}

    // Build the indices
    let matchingHaikuIDs = keys(newHaikus)
    matchingHaikuIDs = sortBy(matchingHaikuIDs, id => newHaikus[id].tsPosted)
    let matchingHaikuIDsByTimePeriod = groupBy(matchingHaikuIDs, id => newHaikus[id].tsMonth);
    matchingHaikuIDsByTimePeriod = mapValues(matchingHaikuIDsByTimePeriod, haikuIDs => sortBy(haikuIDs, id => newHaikus[id].tsPosted))

    return {
        ...state,
        haikus: newHaikus,
        matchingHaikuIDs,
        matchingHaikuIDsByTimePeriod
    }
}

const buildIndicesReducer = (state, action) => {

    const {haikus} = state

    // Build the indices
    const indices = buildIndices(haikus, categoryConfig)

    return {
        ...state,
        indices
    }
}


const filterHaikus = (state, action) => {

    let {haikus, indices, searchString, topics} = state
    const {searchString: newSearchString, topics: newTopics} = action

    // If there's a new search string, use it
    searchString = isNil(newSearchString) ? searchString : newSearchString

    // If there are new topics, use them
    topics =  isNil(newTopics) ? topics : newTopics

    let matchingHaikuIDs = search(indices, searchString, topics)
    matchingHaikuIDs = sortBy(matchingHaikuIDs, id => haikus[id].tsPosted)
    let matchingHaikuIDsByTimePeriod = groupBy(matchingHaikuIDs, id => haikus[id].tsMonth);
    matchingHaikuIDsByTimePeriod = mapValues(matchingHaikuIDsByTimePeriod, haikuIDs => sortBy(haikuIDs, id => haikus[id].tsPosted))

    return {...state, matchingHaikuIDs, matchingHaikuIDsByTimePeriod, searchString, topics}
}

function reducer(state, action) {
    // console.log(action.type)
    // console.log(action)
    // console.log(state)
    switch (action.type) {
        case "LOAD_HAIKUS":
            return loadHaikus(state, action)

        case "BUILD_INDICES":
            return buildIndicesReducer(state, action)

        case "FILTER_HAIKUS":
            return filterHaikus(state, action)

        case "SET_HIGHLIGHT_PERIOD":
            if (action.highlightPeriod && !(action.highlightPeriod in state.matchingHaikuIDsByTimePeriod))
                return state

            return {...state,
                highlightPeriod: action.highlightPeriod}

        case "SCROLL_TO_PERIOD":
            if (action.scrollToPeriod && !(action.scrollToPeriod in state.matchingHaikuIDsByTimePeriod))
                return state

            return {...state,
                scrollToID: null,
                highlightID: null,
                scrollToPeriod: action.scrollToPeriod}

        case "SET_HIGHLIGHT_ID":
            if (action.highlightID && !(action.highlightID in state.haikus))
                return state

            return {...state, highlightID: action.highlightID}

        case "SCROLL_TO_ID":
            if (action.scrollToID && !(action.scrollToID in state.haikus))
                return state

            return {...state,
                scrollToPeriod: null,
                scrollToID: action.scrollToID}

        case "CLEAR_SCROLL_TARGETS":
            return {...state,
                scrollToID: null,
                scrollToPeriod: null
            }

        default:
            return state;
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = {state, dispatch};
    return <Store.Provider value={value}>{props.children}</Store.Provider>;
}