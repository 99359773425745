import React, { useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from '@material-ui/icons/Search';
import {
    useLocation,
    useHistory
} from "react-router-dom";
import {getURLParts, locationToPath, urlParamsToString} from "../util/util";

const styles = theme => ({
    root: {},

});


export const _SearchBox = ({classes, disabled}) => {

    let history = useHistory();
    const location = useLocation()
    const urlParams = (getURLParts(window.location.href).searchParams || {})
    const [queryString, setQueryString] = React.useState(urlParams.q || "");
    const [delayTimer, setDelayTimer] = useState(null)

    const handleChange = (event) => {
        setQueryString(event.target.value);
    };

    useEffect(()=>{
        if (delayTimer) {
            clearTimeout(delayTimer)
        }
        const newTimer = setTimeout(() => {
            const searchString = urlParamsToString({...urlParams, q: queryString})
            history.replace(locationToPath({...location, search: "?" + searchString}))
            setDelayTimer(null)
        }, 200)
        setDelayTimer(newTimer)
    }, [queryString])


    return (<div>
        <TextField
            id="standard-multiline-flexible"
            placeholder={"Search"}
            fullWidth
            disabled={disabled}
            value={queryString}
            onChange={handleChange}
            InputProps={{
                startAdornment: <InputAdornment position="end">
                    {delayTimer ?
                    <CircularProgress size={16}/> : <SearchIcon/>}
                </InputAdornment>

            }}

        />

    </div>)
}

export const SearchBox = withStyles(styles)(_SearchBox)
export default SearchBox
