import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
    root: {
        maxWidth: 1024,
        margin: "0 auto"
    },
});

export const _PageLayout = ({classes, className, leftChildren, rightChildren, children, ...props}, ref) => {

    return (

        <Grid container ref={ref} className={classes.root} {...props}>
            <Grid item xs={1} sm={2}>
                {leftChildren}
            </Grid>
            <Grid item xs={10} sm={8}>
                {children}
            </Grid>
            <Grid item xs={1} sm={2}>
                {rightChildren}
            </Grid>

        </Grid>)
}

export const PageLayout = withStyles(styles)( React.forwardRef(_PageLayout))
export default PageLayout
