import {merge, isNil, isEmpty, mapKeys, keys, values, identity, uniq, toPairs, mapValues, isString, get, isArray, intersection, fromPairs, } from "lodash";
import * as textUtil from "./text";
import Trie from "./Trie";

const buildIndex = haikus => {

    const haikuToIndexString = haiku => {
        return [haiku.content, haiku.title, haiku.description].join(" ");
    };

    let idToKeywordPairs = haikus.map(h => {
        let tokens = textUtil.tokenize(haikuToIndexString(h));
        tokens = tokens.filter(token => !textUtil.isStopWord(token));
        const contentAcronyms = tokens.filter(textUtil.isAcronym);
        tokens = tokens.map(textUtil.stem);

        const entityMIDs = h.entities.map(entity => entity.mid).filter(identity);

        const entityNames = h.entities
            .map(entity => entity.name)
            .filter(identity)
            .map(textUtil.tokenize)
            .flat();

        return [
            h.id,
            uniq([...tokens, ...entityMIDs, ...entityNames, ...contentAcronyms])
        ];
    });

    let output = {};
    idToKeywordPairs.forEach(([id, keywords]) => {
        keywords.forEach(keyword => {
            output[keyword] = [...get(output, keyword, []), id];
        });
    });
    output = mapValues(output, ids => new Set(ids));
    return output;
}

const buildTopicIndex = (haikus, index, categoryConfig) => {

    let output = toPairs(categoryConfig).map(
        ([category, {keywords, mids}]) => {
            const allKeywords = [...keywords, ...mids];

            const simpleKeywords = allKeywords.filter(isString);
            let ids = [...simpleKeywords, ...mids]
                .map(k => [...get(index, k, [])])
                .flat();

            const compoundKeywords = allKeywords.filter(isArray);
            let compoundIds = compoundKeywords
                .map(curKeywords => {
                    let idSets = curKeywords.map(k => [...get(index, k, [])]);
                    return intersection(...idSets);
                })
                .flat();

            return [category, new Set([...ids, ...compoundIds])];
        }
    );
    output = fromPairs(output);
    output = mapKeys(output, (_, k) => `topic:${k}`)

    // Add Bad News + Good News Topics
    output['topic:badnews'] = haikus.filter(h=>(h.sentiment.score<-0.2)).map(h=>h.id)
    output['topic:goodnews'] = haikus.filter(h=>(0.2<=h.sentiment.score)).map(h=>h.id)

    return output;
}

const buildPrefixIndex = (keywords) => {
    let kwTrie = new Trie()
    keywords.forEach(keyword => {
        kwTrie.insert(keyword);
    });
    return kwTrie
}

export const buildIndices = (haikus, categoryConfig) => {
    const allIds = keys(haikus)
    const searchIndex = buildIndex(values(haikus))
    const topicIndex = buildTopicIndex(values(haikus), searchIndex, categoryConfig)

    const keywordIndex = merge(searchIndex, topicIndex, {"": allIds})

    const prefixIndex = buildPrefixIndex(keys(keywordIndex))


    return {
        keyword: keywordIndex,
        prefix: prefixIndex
    }
}

export const search = (indices, queryString, topics) => {

    if (isNil(indices.prefix) || isEmpty(indices.keyword)) {
        return []
    }

    if (isEmpty(queryString) && isEmpty(topics)) {
        return indices.keyword[""]
    }

    const searchTokens = textUtil.tokenize(queryString).map(textUtil.stem)
    const searchKeywordSets = searchTokens.map(token => indices.prefix.find(token))

    const topicKeywordSets = topics.map(topic => [`topic:${topic}`])

    const combinedKeywordSets = [...searchKeywordSets, ...topicKeywordSets]

    const matchingIDs = combinedKeywordSets.map(keywordSet => {
        return uniq(keywordSet.map(keyword => [...indices.keyword[keyword]]).flat())
    })

    const intersectedIDs = intersection(...matchingIDs)
    return intersectedIDs
}