import {naiveTitleCase} from "../util/text/index";
import {mapKeys, defaultsDeep, camelCase} from "lodash"

const initialHaikus = [
    {
        "score": 61946,
        "id": "4tDEJFBrtXeyyMyUHxix5Q",
        "article-url": "https://www.newsweek.com/atheist-group-asks-irs-probe-megachurch-over-pro-trump-rally-says-event-violates-rule-banning-1479953",
        "content": "group asks irs\nto investigate a church\nthat promoted trump",
        "title": "Atheist Group Asks IRS to Probe Megachurch Over Pro-Trump Rally, Says Event Violates Rule Banning Political Participation",
        "ts-posted": "2020-01-01T16:07:46-05:00",
        "description": "The Freedom From Religion Foundation, an atheist group advocating for the separation of church and state, has asked the IRS to investigate a Florida megachurch hosting a Trump rally planned for",
        "sentiment": {"score": -0.4, "magnitude": 0.4},
        "entities": [{"name": "church"}, {"name": "group"}, {"name": "irs"}, {"name": "trump"}]
    }, {
        "score": 62792,
        "id": "tmCtw7Taf2lZcL23nMkJ_g",
        "article-url": "https://www.independent.co.uk/news/world/americas/us-politics/trump-golf-days-presidency-obama-mar-a-lago-bedminster-doonbeg-doral-a9267091.html",
        "content": "When Obama golfed\nTrump complained, but now he golfs\nOne day out of five",
        "title": "Trump attacked for spending '1 in every 5 days' at a golf club in 2019 | The Independent",
        "ts-posted": "2020-01-02T02:27:39-05:00",
        "description": "Donald Trump has been criticised after it was reported he spent nearly one out of every five days playing golf last year \u2013 despite famously telling voters he would be too busy working for such activit",
        "sentiment": {"score": 0.1, "magnitude": 0.1},
        "entities": [{"name": "Trump", "mid": "/m/0cqt90"}, {
            "name": "Obama",
            "mid": "/m/02mjmr"
        }, {"name": "One"}, {"name": "five"}]
    }, {
        "score": 47913,
        "id": "rgAgWmggEjB1v6TPNwFy4g",
        "article-url": "https://thehill.com/homenews/campaign/476472-sanders-campaign-says-345-million-raised-in-fourth-quarter",
        "content": "lots of money raised\nby bernie sanders campaign\nmore than the others",
        "title": "Sanders campaign raised $34.5M in fourth quarter | TheHill",
        "ts-posted": "2020-01-02T14:05:22-05:00",
        "description": "Sen. Bernie Sanders\u2019s (I-Vt.) campaign said Thursday that the Democratic presidential hopeful raked in more than $34.5 million in fundraising during the fourth quarter, putting the senator in a strong",
        "sentiment": {"score": -0.3, "magnitude": 0.3},
        "entities": [{"name": "lots"}, {"name": "money"}, {"name": "bernie sanders"}, {"name": "others"}]
    }, {
        "score": 53026,
        "id": "x7gYwoHeeztu20sdaq7aWw",
        "article-url": "https://news.sky.com/story/australia-wildfires-scott-morrison-told-to-p-off-by-angry-residents-11899728",
        "content": "Stay out. Don't come back.\nThat's what the pissed locals said.\nHe didn't listen.",
        "title": "Australia wildfires: Scott Morrison told to 'p*** off' by angry residents | World News | Sky News",
        "ts-posted": "2020-01-02T16:26:22-05:00",
        "description": "Locals heckle the country's leader over the handling of fires which have claimed a number of lives and left thousands homeless. ",
        "sentiment": {"score": -0.8, "magnitude": 3.4},
        "entities": [{"name": "locals"}]
    }, {
        "score": 42193,
        "id": "3r-ffpUsTM_qiw3lJcX8BQ",
        "article-url": "https://www.nbcchicago.com/news/local/illinois-reveals-first-day-marijuana-sales/2195516/",
        "content": "three million dollars\nfrom the sale of legal weed\nIllinois gets high",
        "title": "Nearly $3.2M in Legal Cannabis Sold in Illinois on First Day, State Says \u2013 NBC Chicago",
        "ts-posted": "2020-01-02T22:27:37-05:00",
        "description": "More than 77,000 transactions netted nearly $3.2 million in marijuana product sales on the first day of legal cannabis in Illinois, the state announced...",
        "sentiment": {"score": -0.5, "magnitude": 0.5},
        "entities": [{"name": "sale"}, {"name": "weed"}, {
            "name": "Illinois",
            "mid": "/m/03v0t"
        }, {"name": "three million dollars"}, {"name": "three million"}]
    }, {
        "score": 46720,
        "id": "mRGp5Z4Y3oOVJLwKq2iR7Q",
        "article-url": "https://www.commondreams.org/news/2020/01/03/video-trump-warning-our-president-will-start-war-iran-because-he-has-absolutely-no",
        "content": "Trump says pres will start\nWar with Iran to save job\nBut he is lying",
        "title": "Video of Trump Warning 'Our President Will Start a War With Iran Because He Has Absolutely No Ability to Negotiate' Resurfaces | Common Dreams News",
        "ts-posted": "2020-01-03T16:55:41-05:00",
        "description": "\"The only way he figures that he's going to get reelected\u2014and as sure as you're sitting there\u2014is to start a war with Iran,\" Trump said of Obama in 2011.",
        "sentiment": {"score": -0.6, "magnitude": 0.6},
        "entities": [{"name": "Trump", "mid": "/m/0cqt90"}, {"name": "pres"}, {
            "name": "Iran",
            "mid": "/m/03shp"
        }, {"name": "War", "mid": "/m/082cb"}, {"name": "job"}]
    }, {
        "score": 42460,
        "id": "4DKbC1V0_utPKGRdKFrYMw",
        "article-url": "https://www.thedailybeast.com/bernie-sanders-war-in-iran-would-be-bigger-disaster-than-iraq",
        "content": "bernie sanders says\ndon't go to war with iran\nit's a disaster",
        "title": "Bernie Sanders: War in Iran Would Be Bigger Disaster Than Iraq",
        "ts-posted": "2020-01-03T18:31:01-05:00",
        "description": "\u201cIf you think the war in Iraq was a disaster, my guess is that war in Iran would be even worse,\u201d the Vermont senator said.",
        "sentiment": {"score": -0.8, "magnitude": 0.8},
        "entities": [{"name": "bernie sanders"}, {"name": "disaster"}, {"name": "war"}, {
            "name": "iran",
            "mid": "/m/03shp"
        }]
    }, {
        "score": 55727,
        "id": "eMXZY71RGU7B0XD6qeHdSw",
        "article-url": "https://www.commondreams.org/news/2020/01/03/right-now-moment-decide-if-you-are-pro-peace-or-not-ocasio-cortez-joins-progressive",
        "content": "There are only some\nhonest politicians so\nlets show them we care",
        "title": "'Right Now Is the Moment to Decide If You Are Pro-Peace or Not': Ocasio-Cortez Joins Progressive Chorus Against War With Iran | Common Dreams News",
        "ts-posted": "2020-01-03T23:13:24-05:00",
        "description": "\"Congress now has a moral and legal obligation to reassert its power to stop this war and protect innocent people from horrific consequences.\"",
        "sentiment": {"score": -0.4, "magnitude": 0.4},
        "entities": [{"name": "politicians"}]
    }, {
        "score": 28811,
        "id": "W16rhxDeDYfvVtEVidjYWg",
        "article-url": "https://globalnews.ca/news/6362535/vancouver-woman-ex-200k-trashing-reputation-online/",
        "content": "woman has to pay\nbecause she trashed ex boyfriend\nonline has a price",
        "title": "Vancouver woman ordered to pay ex $200K after trashing his reputation online  | Globalnews.ca",
        "ts-posted": "2020-01-04T06:22:48-05:00",
        "description": "The judge found the woman had mounted a malicious and spiteful campaign against her ex \"that was as relentless as it was extensive.\"",
        "sentiment": {"score": -0.7, "magnitude": 0.7},
        "entities": [{"name": "woman"}, {"name": "ex boyfriend"}, {"name": "price"}]
    }, {
        "score": 41493,
        "id": "ruVCPs6dYu0r2kEEzD9wPg",
        "article-url": "https://www.salon.com/2020/01/04/yale-psychiatrist-urges-pelosi-request-72-hour-mental-health-hold-on-trump-after-iran-attack/",
        "content": "Yale psychiatrist\nsays trump a mental health risk\nafter a drone strike",
        "title": "Yale psychiatrist urges Pelosi: Request 72-hour mental health hold on Trump after Iran attack | Salon.com",
        "ts-posted": "2020-01-04T15:27:17-05:00",
        "description": "Yale psychiatrist: Pelosi can request \"72-hour hold\" of Trump after he responded to impeachment with Iran attack",
        "sentiment": {"score": -0.7, "magnitude": 0.7},
        "entities": [{"name": "psychiatrist"}, {
            "name": "Yale",
            "mid": "/m/08815"
        }, {"name": "mental health risk"}, {"name": "drone strike"}]
    }, {
        "score": 40805,
        "id": "PnYmsHHjW-LuaBdoHvxxOg",
        "article-url": "https://www.independent.ie/world-news/middle-east/thousands-chant-death-to-america-at-funeral-of-irans-top-general-qassem-soleimani-38833409.html",
        "content": "unrest in Iraq\nsometimes an unmanned drone strike\nisn't that simple",
        "title": "Thousands chant 'death to America' at funeral of Iran's top general Qassem Soleimani - Independent.ie",
        "ts-posted": "2020-01-04T18:21:12-05:00",
        "description": "Thousands of mourners have marched in a funeral procession through Baghdad for Iran's top general and Iraqi militant leaders, who were killed in a US air strike, chanting \"Death to America\".",
        "sentiment": {"score": -0.5, "magnitude": 0.5},
        "entities": [{"name": "unrest"}, {"name": "drone strike"}, {"name": "Iraq", "mid": "/m/0d05q4"}]
    }, {
        "score": 49497,
        "id": "2S1gr8I9slnTz-jPM8fL8A",
        "article-url": "https://www.commondreams.org/news/2020/01/05/ocasio-cortez-says-trump-monster-threatening-target-and-kill-innocent-families-women",
        "content": "is trump a monster\nnew york red says yes he is\ncommitting war crimes",
        "title": "Ocasio-Cortez Says Trump Is a 'Monster' for 'Threatening to Target and Kill Innocent Families, Women, and Children' in Iran | Common Dreams News",
        "ts-posted": "2020-01-05T14:43:21-05:00",
        "description": "\"This is a war crime,\" said the New York Democrat.",
        "sentiment": {"score": -0.4, "magnitude": 0.4},
        "entities": [{"name": "new york red"}, {"name": "war crimes"}]
    }, {
        "score": 75573,
        "id": "8EZxMnOaAk6RKDYt_Qx7XA",
        "article-url": "https://www.commondreams.org/news/2020/01/05/iraqi-parliament-votes-expel-all-american-troops-and-submit-un-complaint-against-us",
        "content": "expel us troops\niraqi parliament votes\nthey want to be free",
        "title": "Iraqi Parliament Votes to Expel All American Troops and Submit UN Complaint Against US for Violation of Sovereignty | Common Dreams News",
        "ts-posted": "2020-01-05T17:57:39-05:00",
        "description": "\"What happened was a political assassination. Iraq cannot accept this.\"",
        "sentiment": {"score": -0.7, "magnitude": 0.7},
        "entities": [{"name": "troops"}, {"name": "parliament votes"}, {"name": "iraqi", "mid": "/m/0d05q4"}]
    }, {
        "score": 28265,
        "id": "YaEP3FlRPi_a4Fjv1XXDvA",
        "article-url": "https://newmatilda.com/2020/01/05/morrison-touts-for-lib-party-donations-on-govts-bushfire-response-ad/",
        "content": "Aussie PM gaffe\nasks for party donations\nwhile Australia burns",
        "title": "Morrison Touts For Lib Party Donations On Govt\u2019s Bushfire Response Ad - New Matilda",
        "ts-posted": "2020-01-05T18:40:15-05:00",
        "description": "The political crisis engulfing Prime Minister Scott Morrison over his response to Australia\u2019s growing climate emergency has deepened overnight, after he linked a video advertisement promoting the gove",
        "sentiment": {"score": -0.4, "magnitude": 0.4},
        "entities": [{"name": "Aussie"}, {"name": "gaffe"}, {"name": "party donations"}, {
            "name": "Australia",
            "mid": "/m/0chghy"
        }]
    }, {
        "score": 42376,
        "id": "G4ZRQbdN71jC1rKNF-QrzQ",
        "article-url": "https://apnews.com/Iran",
        "content": "Virus in Iran\nRevolutionary Guard\nCredibility",
        "title": "Iran",
        "ts-posted": "2020-01-05T21:12:18-05:00",
        "description": "News from The Associated Press, the definitive source for independent journalism from every corner of the globe.",
        "sentiment": {"score": -0.8, "magnitude": 0.8},
        "entities": [{"name": "Virus"}, {"name": "Credibility"}, {
            "name": "Iran",
            "mid": "/m/03shp"
        }, {"name": "Revolutionary Guard", "mid": "/m/04q3gn"}]
    }, {
        "score": 48868,
        "id": "V1sFo4NGjRQuF64ChTNJZg",
        "article-url": "https://www.npr.org/2020/01/05/785672201/deceased-gop-strategists-daughter-makes-files-public-that-republicans-wanted-sea",
        "content": "Republican files\nmade public by the daughter\nof dead strategist",
        "title": "Stephanie Hofeller Shares Redistricting Files From Thomas Hofeller's Hard Drives : NPR",
        "ts-posted": "2020-01-05T22:12:02-05:00",
        "description": "Republicans in North Carolina fought in court to stop computer files found on the redistricting expert's hard drives from going public. Now his daughter, Stephanie Hofeller, is sharing them online.",
        "sentiment": {"score": -0.2, "magnitude": 0.2},
        "entities": [{"name": "files"}, {
            "name": "Republican",
            "mid": "/m/07wbk"
        }, {"name": "daughter"}, {"name": "strategist"}]
    }, {
        "score": 36047,
        "id": "FzC10BYvm6vtPQCY_doqdw",
        "article-url": "https://www.businessinsider.com/trump-claims-tweets-count-as-official-congress-notification-iran-attacks-2020-1",
        "content": "trump says twitter posts\nare official messages\nto us congress",
        "title": "Trump claims tweets count as official Congress notification about Iran - Business Insider",
        "ts-posted": "2020-01-06T00:34:30-05:00",
        "description": "There is no basis for President Trump's claim, because tweets do not constitute official congressional notification.",
        "sentiment": {"score": -0.3, "magnitude": 0.3},
        "entities": [{"name": "twitter posts"}, {"name": "trump"}, {"name": "congress"}]
    }, {
        "score": 60826,
        "id": "pmr2LuWg7WtOnO-rz4KfsQ",
        "article-url": "https://www.politico.com/news/2020/01/06/alexandria-ocasio-cortez-joe-biden-not-same-party-094642",
        "content": "AOC makes claim\nJoe Biden is too centrist\nShe is progressive",
        "title": "AOC: 'In any other country, Joe Biden and I would not be in the same party' - POLITICO",
        "ts-posted": "2020-01-06T16:29:18-05:00",
        "description": "Ocasio-Cortez labeled House Democrats' centrist wing \"the tea party of the left.\"",
        "sentiment": {"score": -0.5, "magnitude": 0.5},
        "entities": [{"name": "AOC"}, {"name": "claim"}, {"name": "Joe Biden", "mid": "/m/012gx2"}]
    }, {
        "score": 44417,
        "id": "xboYAzDkzKiESZ7y7OUHGA",
        "article-url": "https://www.vice.com/en_us/article/y3mg9x/ice-detention-center-captain-was-on-a-neo-nazi-website-and-wanted-to-start-a-white-nationalist-group",
        "content": "ICE center captain\nsupports racists and Nazis\nwhat a piece of trash",
        "title": "ICE Detention Center Captain Was on a Neo-Nazi Website and Wanted to Start a White Nationalist Group - VICE",
        "ts-posted": "2020-01-07T01:23:52-05:00",
        "description": "\u201cDeep down, no one really gives a shit about racism,\u201d Travis Frey, who was then a senior employee at an Indianapolis jail, wrote on Iron March.",
        "sentiment": {"score": -0.8, "magnitude": 0.8},
        "entities": [{"name": "ICE"}, {"name": "center captain"}, {"name": "racists"}, {"name": "Nazis"}, {"name": "trash"}, {"name": "piece"}]
    }, {
        "score": 53857,
        "id": "zLIgb8LvyGBziwvBOFlPqw",
        "article-url": "https://www.businessinsider.com/trump-officials-defend-targeting-iran-cultural-sites-war-crime-2020-1",
        "content": "Trump threatens war crime\nofficials say he didn't\nwe all know he did",
        "title": "Trump officials struggle to defend plan to attack Iran cultural sites - Business Insider",
        "ts-posted": "2020-01-07T03:53:34-05:00",
        "description": "President Donald Trump's advisers have bent over backward to argue he didn't threaten to commit a war crime, despite his statements to the contrary.",
        "sentiment": {"score": -0.7, "magnitude": 0.7},
        "entities": [{"name": "Trump", "mid": "/m/0cqt90"}, {"name": "war crime officials"}]
    }, {
        "score": 50058,
        "id": "X_Mbn2blh-tL2X4xyBY1Wg",
        "article-url": "https://fivethirtyeight.com/features/trump-is-the-most-unpopular-president-since-ford-to-run-for-reelection/",
        "content": "Trump approval low\nreelection is looming\nonly Ford was worse",
        "title": "Trump Is The Most Unpopular President Since Ford To Run For Reelection | FiveThirtyEight",
        "ts-posted": "2020-01-07T14:31:12-05:00",
        "description": "On Jan. 1, 42.6 percent of Americans approved of President Trump\u2019s job performance.",
        "sentiment": {"score": -0.8, "magnitude": 0.8},
        "entities": [{"name": "approval"}, {
            "name": "Trump",
            "mid": "/m/0cqt90"
        }, {"name": "reelection"}, {"name": "Ford"}]
    }, {
        "score": 38325,
        "id": "ITQbTNhJx0qf36p37LbHAw",
        "article-url": "https://www.vox.com/policy-and-politics/2020/1/7/21002895/bernie-sanders-2020-electability",
        "content": "Senator Sanders\ncan unify Democrats\nand other voters",
        "title": "Bernie Sanders could be the most electable Democrat in 2020 - Vox",
        "ts-posted": "2020-01-07T16:04:29-05:00",
        "description": "The Vermont socialist has a strong case and some good ideas on key issues.",
        "sentiment": {"score": -0.1, "magnitude": 0.1},
        "entities": [{"name": "Sanders", "mid": "/m/01_gbv"}, {"name": "voters"}, {
            "name": "Democrats",
            "mid": "/m/0d075m"
        }]
    }, {
        "score": 39854,
        "id": "Bv1W_wvMovppBL2FN7i1WA",
        "article-url": "https://lawandcrime.com/high-profile/approximately-100-percent-of-trumps-tariffs-were-paid-by-american-buyers-report/",
        "content": "Trump lies continue\ntariffs paid by the US\nnot China you fool",
        "title": "Americans Pay for Roughly '100 Percent' of Tariffs: Report | Law & Crime",
        "ts-posted": "2020-01-07T19:53:16-05:00",
        "description": "Based on a working paper authored by economist at the Federal Reserve Bank of New York Mary Amiti, Columbia University professor David E. Weinstein, and Stephen J. Redding of Princeton, the trio's res",
        "sentiment": {"score": -0.8, "magnitude": 0.8},
        "entities": [{"name": "Trump", "mid": "/m/0cqt90"}, {"name": "tariffs"}, {
            "name": "US",
            "mid": "/m/09c7w0"
        }, {"name": "China", "mid": "/m/0d05w3"}]
    }, {
        "score": 22934,
        "id": "5vtFl6QimUqnQ-dlYzmN_A",
        "article-url": "https://www.bbc.com/news/world-us-canada-51026744",
        "content": "Harvey used his phone\nviolating another \njust like a rapist",
        "title": "Harvey Weinstein rebuked by judge for using phone in court - BBC News",
        "ts-posted": "2020-01-07T20:54:27-05:00",
        "description": "A New York judge warns the former movie mogul he could face life behind bars for texting in court.",
        "sentiment": {"score": -0.7, "magnitude": 0.7},
        "entities": [{"name": "Harvey"}, {"name": "phone"}, {"name": "another"}, {"name": "rapist"}]
    }, {
        "score": 58224,
        "id": "_D4oFcqYH1yymuUR-H_Wqw",
        "article-url": "https://www.independent.co.uk/voices/bernie-sanders-democrat-nominee-biden-pete-buttigieg-elizabeth-warren-funding-a9274341.html",
        "content": "Will it be Bernie?\nvoters want a progressive\nBiden is not it",
        "title": "Against all odds, it looks like Bernie Sanders might be the Democratic nominee after all | The Independent",
        "ts-posted": "2020-01-07T22:05:05-05:00",
        "description": "With the first-in-the-nation Iowa Democratic caucus less than a month away, even top-tier presidential contenders like former Vice President Joe Biden and Vermont Senator Bernie Sanders are struggling",
        "sentiment": {"score": -0.3, "magnitude": 0.7},
        "entities": [{"name": "Bernie", "mid": "/m/01_gbv"}, {"name": "voters"}, {"name": "Biden", "mid": "/m/012gx2"}]
    }
]

const dataOverrides = {
    "vUsTjYLZOf63luscocS8rA": {sentiment: {score: 1}},
    "HvggDxf7D5ePDPXDyTqcWQ": {sentiment: {score: 0.75}},
    "MEPEcnhl1BtYmyFMfeWtng": {sentiment: {score: -1}},
    "tmCtw7Taf2lZcL23nMkJ_g": {sentiment: {score: 0}},
    "h8BeNwgQ3TIhe8QQJdD6Mg": {sentiment: {score: 2}},
    "DJC2L09FaH8pPvzWnOAYqQ": {sentiment: {score: -0.25}},
    "_wxRHRerhjqHudqMBpfV7Q": {sentiment: {score: 0}},
    "AQILZdjFE9eSKYYWRVSmEA": {sentiment: {score: 0.5}},
    "LhwgoTRy3rTZla75fjnvog": {sentiment: {score: 0}},
    "WKvrThskapxT8IrJA7FBqQ": {sentiment: {score: 0}},
    "MOzwyOo2gJCNeX7dIYwXyQ": {sentiment: {score: -0.25}},
    "FKlAzK76Q9niUxQrMKPekA": {sentiment: {score: 0}},
    "XVcUETIIyafYegM0VIZiyg": {sentiment: {score: -0.75}},
    "HqunuPqvDSqNpgKgU6npUA": {sentiment: {score: 1}},
    "_83tGCHnxRs8gqCwwYBhIQ": {sentiment: {score: -0.25}},
    "MKWySQGBf-E8S7xKqrbOjw": {sentiment: {score: -0.25}},
    "yTmfP2vpdk0z2EQaLe8RVA": {sentiment: {score: 0}},
    "8ljk1eXLrsBwEpCFMQdBEQ": {sentiment: {score: 0}},
    "WsOTlyar97PQ0GekAx4oqA": {sentiment: {score: -0.25}},
    "gIQxpqygfCwcxUMDDadhwg": {sentiment: {score: -0.25}},
    "mxBbdny-epZmsbDqY77OcQ": {sentiment: {score: 0}},
    "OhvWPGmiONdxiPtT5l-5wQ": {sentiment: {score: 0}},
    "xlimfx4nfOoGxyet_opHPw": {sentiment: {score: -0.25}},
    "mtOzWIiPIl1BFS3OS6TmGw": {sentiment: {score: 1}},
    "hdnERF0a_csruzKnxg4tlA": {sentiment: {score: -0.25}},
    "r28CG-fb94YQ_P-b1P5aDg": {sentiment: {score: -0.25}},
    "hn72mXfbTQBu_2EXoBKGGw": {sentiment: {score: 0}},
    "CrA3osRNtH4hIUbJ74RyvQ": {sentiment: {score: 0}},
    "ITjiJ5t65vcOOgwFt805tg": {sentiment: {score: 0}},
    "4b6m7Lj7EpD6WJ4ULEpkLA": {sentiment: {score: -0.25}},
    "vcrE9dKe4-NzGcCra7f2nQ": {sentiment: {score: -0.5}},
    "KBPyvKe2d28LuGI5TTawug": {sentiment: {score: -0.25}},
    "CDVTeVqyoIMsd7AeEJqHaw": {sentiment: {score: 0}},
    "-VM4XUWNrcFlGymW4RoH-Q": {sentiment: {score: 0}},
    "H23slp6VNNUp3XdCYmmSSw": {sentiment: {score: -0.25}},
    "wmQGuxd7klZHB0Jn-tvMeg": {sentiment: {score: 0}},
    "8f8b7mWVWoEgcVzLAkHsUg": {sentiment: {score: -0.25}},
    "dfcCRm_cJT1Pt2oPEOynIQ": {sentiment: {score: 1}},
    "PMM3M3R5WgIt9JHgzDpyxQ": {sentiment: {score: 0}},
    "84Y1Z7qEOqUOjMzNNC3mKQ": {sentiment: {score: -0.25}},
    "7YRP7WWg8OGAsdkxVahp4g": {sentiment: {score: -0.5}},
    "HmIheLiFM4sbrHA-8xUhqA": {sentiment: {score: 0}},
    "WwaC8A90FFVFsCR8aAAX4g": {sentiment: {score: -0.25}},
    "m2pGhBlpDbb9FjBrDiss-g": {sentiment: {score: -0.25}},
    "RvMs6IjPUcuGnkxIwcGfrQ": {sentiment: {score: 0}},
    "4qWl9dyncapNfOWc9bV4_Q": {sentiment: {score: 0}},
    "Ar3MFFUiH7ST2L7BWyyAwg": {sentiment: {score: -0.25}},
    "XLCmAiOxto5mGXcdA50oNA": {sentiment: {score: -0.25}},
    "r-5EEnTKv95edqf5po64Qg": {sentiment: {score: -0.25}},
    "Gi8x9N8rqMzJT6V-XT6GgQ": {sentiment: {score: 0}},
    "yiACNb4gUl4Tf9pR-TqJ0g": {sentiment: {score: 0}},
    "1CLYexXeMv6inBnkEXslqw": {sentiment: {score: 0}},
    "cj78zir39GKXEjcXrchn5g": {sentiment: {score: -0.5}},
    "Lx5wEMVG-KQ1GqdWkqxuzg": {sentiment: {score: -0.25}},
    "UjAlgbbeegsDt5iHJsaxKw": {sentiment: {score: -0.5}},
    "NPpZbV3edGF85FvdEjbxoA": {sentiment: {score: 0}},
    "kZ64DJVpNwKuwPQ5oMI42A": {sentiment: {score: -0.25}},
    "4saBq_WRd1NHoydYapZl1w": {sentiment: {score: 0}},
    "cKQJ9VQHjbewS6e_svO3UQ": {sentiment: {score: -0.5}},
    "5ungLWs58KRHNk7qWG2O9Q": {sentiment: {score: 0}},
    "IPtBJPhqtg1cx-cn8UU5Qw": {sentiment: {score: 0}},
    "He58cys-OY0kM9UxkgAx-Q": {sentiment: {score: -0.25}},
    "Bu0obfXudBIFoz5fvc6kIw": {sentiment: {score: -0.25}},
    "eNfflKaIO3c48m8XMQba7w": {sentiment: {score: -0.5}},
    "PnwlTCPg3W7X2vheE4j6oQ": {sentiment: {score: -0.5}},
    "9_3O9SY1J9cR3q5yIIb2Ew": {sentiment: {score: 0}},
    "H4zS7D8Sv5m7SOQcxbgW5w": {sentiment: {score: 0}},
    "sYUMPrz1ayz0q_jD0T4hlQ": {sentiment: {score: 0}},
    "IDtBORxOgZvp9rMO3dxE2g": {sentiment: {score: 0}},
    "Bg3ok8-eVvrLA_M02Ur22A": {sentiment: {score: 0}},
    "WMfewmQnAOuOa7Lk4iZ-Zg": {sentiment: {score: 0}},
    "BxiMBxTvfB1fgyptAICYzw": {sentiment: {score: 0.25}},
    "bvTd6pGl2eBnlGOrJNc6sw": {sentiment: {score: -0.25}},
    "0_AzckqLox_jDBCVH1zdLg": {sentiment: {score: 0}},
    "pYj_vz46zq4Tn_nEwceoBw": {sentiment: {score: 0}},
    "nMEIgHc1HvCR76l166B4mg": {sentiment: {score: -0.25}},
    "TSEIw3gN0XoUqPgg5BVahQ": {sentiment: {score: 0}},
    "8mNZ9Kf0OwExbOKlfNm2Ig": {sentiment: {score: -0.25}},
    "m8szqijWrI1FC5tk_lbTlA": {sentiment: {score: 0}},
    "zhfxCaHEh7yZqPIJPtaLEg": {sentiment: {score: -0.5}},
    "g3D8q8Su26VvDCcsY5QCqw": {sentiment: {score: -0.5}},
    "BCTY-Fr3uatZh7kwPDgOng": {sentiment: {score: 0}},
    "V9nrOp4LONHCS9grChB3Cg": {sentiment: {score: -0.25}},
    "ORBwOQh8UDJ3mZYOV47TaA": {sentiment: {score: -0.25}},
    "yPGvqAPo1QTregWhb6zzxA": {sentiment: {score: -0.25}},
    "KS6gTFA0ixgbGby3flu82g": {sentiment: {score: 0}},
    "BH8s_vMZ1_N8JHGxVv3w-A": {sentiment: {score: 0}},
    "9ZLxhaM0U3kcOyd8QGQAZQ": {sentiment: {score: 0.5}},
    "wjwQn9HLxMVqTLTlrQsZPA": {sentiment: {score: 0}},
    "4mcwG-gaAcgWUxk1LyQPxA": {sentiment: {score: 0}},
    "lxdJnAqLsLxoemHhmIiknw": {sentiment: {score: -0.25}},
    "4Cp39le2O9Z-na9moT11dA": {sentiment: {score: -0.25}},
    "KpGI1NqTEnOC0CKcgzd25Q": {sentiment: {score: -0.25}},
    "5056wqNujrzJ4f1b5KxYvg": {sentiment: {score: -0.25}},
    "orxBNEa-62c1Oj0-2rz78g": {sentiment: {score: -0.25}},
    "zH4yZrkb0tMFMxaxUCYwKQ": {sentiment: {score: -0.25}},
    "Vjt5lmp607B1LLSHzfOihg": {sentiment: {score: -0.25}},
    "mUD8NJqC2qxrOfhJezf18g": {sentiment: {score: 0}},
    "KrjEfwmLWemC_R5lIu12FA": {sentiment: {score: -0.25}},
    "p9rINX9-wNkGUHqW25Sx2A": {sentiment: {score: 0}},
    "rJ_-Hr7Q6MOAaQxGYYvqgA": {sentiment: {score: -0.25}},
    "GKMb8_tOE4PIJsOOhtEkkg": {sentiment: {score: 0}},
    "Nv5JWwoWDEZXPUIkXBI5Nw": {sentiment: {score: 0}},
    "zdXYJrHSvpQJs1PNoC1wRQ": {sentiment: {score: -0.25}},
    "Q3BjdpYxJnMz2Hil0RXz5g": {sentiment: {score: -0.25}},
    "jmVVH4stJX33Z3BaqZ24Bg": {sentiment: {score: 0}},
    "DStrS8ojqYPmwqf1bFdfUA": {sentiment: {score: 0}},
    "WFEb6R1A9ZV6-NtbTgh7RQ": {sentiment: {score: -0.25}},
    "yi2i5cmFHWypEwHKGc92bA": {sentiment: {score: -0.25}},
    "6QKkxlrmaO-e_vEIrQzreQ": {sentiment: {score: -0.25}},
    "GiAIa4R1vDTomaoOMjM6Rw": {sentiment: {score: -0.25}},
    "6Y4uciiI8C8-bDtOOF2aag": {sentiment: {score: 0}},
    "rjXQXNMRvpRsBOzW_SIXEA": {sentiment: {score: 0}},
    "namv4__g35Mk7HQ4Ou4LFA": {sentiment: {score: 0}},
    "wK4e5SjUlEoXar0Pja9S-A": {sentiment: {score: 0}},
    "v4KcmO_HJpgCwxm_gNArmg": {sentiment: {score: -0.25}},
    "zXAuRslEAHV6ka_43jW5MA": {sentiment: {score: -0.25}},
    "GdVxGMgIcJkZ4hrueGBmtw": {sentiment: {score: 0.5}},
    "VJPNhRBQbK2ogvMQ_RxPvw": {sentiment: {score: 0}},
    "okFBFK5rkXKGS1yroN4X6Q": {sentiment: {score: 0.5}},
    "zdhWq4GUAA4bjx7aq87K4A": {sentiment: {score: -0.25}},
    "m3deg9HN4rJsRUQPryKwMg": {sentiment: {score: 0}},
    "TP-8_DHzLnQqMc03FqAmXA": {sentiment: {score: 0.25}},
    "MK033syqTU2WoLRykvEg1A": {sentiment: {score: -0.5}},
    "ryA6Erw_4kK36zT3N47NNQ": {sentiment: {score: -0.5}},
    "N0xr2X2YMwUF9NCyaqGWBg": {sentiment: {score: 0}},
    "Rn-G1zJ2NekBsvDVl1Tx0Q": {sentiment: {score: 0}},
    "mUEGoGVksXxzFhZW1ukdlw": {sentiment: {score: 0}},
    "hNVH3PQQ2mBBzcovCfSgZA": {sentiment: {score: 0}},
    "T4wJ8ow9F3mVW6BXBiMQ9g": {sentiment: {score: -0.25}},
    "aNk3d2Y4Z71Jywbv_XrtGw": {sentiment: {score: 0}},
    "yZIiXoz3AT-g1dUowViNSg": {sentiment: {score: -0.25}},
    "yA3WGo311D2X-ffi_y-ZUw": {sentiment: {score: 0}},
    "9RTPo5beEMh306TDVffTPA": {sentiment: {score: 0}},
    "G4ZRQbdN71jC1rKNF-QrzQ": {sentiment: {score: 0}},
    "zFKSqXmadSrQOnjBNJQpcw": {sentiment: {score: -0.5}},
    "aI0nmN18ILXkv7I9Tsd98Q": {sentiment: {score: -0.5}},
    "FPZ3CZIBItiT3m7ANOUdpA": {sentiment: {score: 0}},
    "JNlZhwrqb5l1HyUy2Bqx7w": {sentiment: {score: -0.5}},
    "Q1oWHgscr1aD6YbwYY_OBQ": {sentiment: {score: -0.5}},
    "vcOkXUJT7ye8WUw2FEMdFA": {sentiment: {score: -0.5}},
    "Oa9q0Y82kD4XiM332ez88A": {sentiment: {score: -0.25}},
    "Vo_DIxUsxKoBr-s54bc8UA": {sentiment: {score: 0.5}},
    "eqgQB0tbtYBP-JBVXcNhpQ": {sentiment: {score: 0}},
    "-VE-9G8L9PS_Mnu8Th2YYQ": {sentiment: {score: -0.25}},
    "rLmcLB22EJ_MS-xXpbJ4aQ": {sentiment: {score: -0.25}},
    "o9NiRZ8-ipNjURfKb1o8NA": {sentiment: {score: -0.25}},
    "ttGu705jCa0z-79wpEyoIQ": {sentiment: {score: -0.25}},
    "j9l95rJslIeJY63TRuIdEw": {sentiment: {score: -0.25}},
    "5Ut7A9orJSKJzVwBm6jkew": {sentiment: {score: 0}},
    "kw1EpIIXhe31do_R4exiFQ": {sentiment: {score: 0}},
    "m85rFTXUpVbvcHmlmWFN-g": {sentiment: {score: -0.25}},
    "ZGAE2cchqY3cOou9FGZqdw": {sentiment: {score: -0.25}},
    "l_aY4U-uLEz-zEhMjM6OUg": {sentiment: {score: -0.25}},
    "SpS-bHAkOoInvq4qfm02vQ": {sentiment: {score: 0}},
    "_TB8t2v4Y5CREHvSfCcY3g": {sentiment: {score: 0}},
    "C4DcfTQ75Xm_43zYYyRdgg": {sentiment: {score: -0.5}},
    "4JK2O7rnxfTlAJpfOwj6fg": {sentiment: {score: 0}},
    "yoApd8VMF8Su2Nx62YMz7w": {sentiment: {score: -0.25}},
    "ZKqi5HqdRYIrCo3laouTow": {sentiment: {score: 0}},
    "VVEgIL8iggMBx5cp3": {sentiment: {score: 0.75}},
    "aEDKg2StwJjY-G1zW6aE_g": {sentiment: {score: 0.25}},
    "ZKrkZuQd0bPJXLX4vaax6Q": {sentiment: {score: 0.75}},
    "oDJ2jpJyyzrH0ZTY48dfTA": {sentiment: {score: 0.5}},
    "7e-xqwYu2p1KWScINNQ_ZQ": {sentiment: {score: 0.25}},
    "GqxBxKc5A1Y7sVo3YP6M8g": {sentiment: {score: 1.1}},
    "zklo_BlMRaqUMTr75PM2Lg": {sentiment: {score: 0.5}},
    "ThDgH9-A308u31ONhOeUBQ": {sentiment: {score: 0.25}},
    "9ArKtjCM3yZleMmFxW9rcA": {sentiment: {score: 0.6}},
    "nFquLLByMxIReXUOzYc8iQ": {sentiment: {score: 0.25}},
    "l8eWbr3QWOEPg-zbu20RcA": {sentiment: {score: 0.25}},
    "yf_hheEe-8GWhM1sS7GMVA": {sentiment: {score: 0.25}},
    "NBMWLQk7mfuJaUOpHlseJw": {sentiment: {score: 0.25}},
    "u2FKtDGZ_7X9MT1lFcNIrQ": {sentiment: {score: 0.25}},
    "xP8GBD8mr6P1DAVw7lFkgQ": {sentiment: {score: 0.4}},
    "FMkSNUPK3XO4sZ6ECy957Q": {sentiment: {score: 0.65}},
    "AJbpw22b9LaoewIjR9_JOA": {sentiment: {score: 0.3}},
    "KY2zrJ5DHnBiyPw4F366ZA": {sentiment: {score: 0.2}},
    "TUzc6Ofoh54t7gQoMHC3YQ": {sentiment: {score: 0.25}},
    "ZUkFPbxdNlvh_v3fSYUO_g": {sentiment: {score: 0.3}},
    "5LKczuZdcn1DfWW137ADeg": {sentiment: {score: 0.5}},
    "mB41oqb5_EWWumLivmoD6g": {sentiment: {score: 0.2}},
    "5R7vfkxxoBqTSgFw15LATg": {sentiment: {score: 0.25}},
    "IAhBSS7qDx17umTArc-x9Q": {sentiment: {score: 0.25}},
    "W1gw8VdVRQ-nLoxu67-maA": {sentiment: {score: 0.25}},
    "aUqkaSjBDlh8YD8jzqQEUg": {sentiment: {score: 0.5}},
    "Rp8H_UyUOXnD8fdK8TaRJQ": {sentiment: {score: 0.25}},
    "njo2diIw5zG3ZYprkGAE5w": {sentiment: {score: 0.35}},
    "gFsP6XxlF-1h06HmMF8VFA": {sentiment: {score: 0.2}},
    "6xjESyTen4tjefJSioWQHg": {sentiment: {score: 0}},
    "E2w1nlpN3JocorzeelyDYg": {sentiment: {score: 0.5}},
    "yTcY4kjqz_IkQa4OoHEXpw": {sentiment: {score: 0.2}},
    "6MVBLS3NdsvY7svpkMSeQQ": {sentiment: {score: 0.2}},
    "qOTmSeEmEUOnxWfMaTo7JQ": {sentiment: {score: 0.7}},
    "khUEPG-Pj5-ijt57kkJVwA": {sentiment: {score: 0.2}},
    "8DNmIb57gYzB4rgCdf08HA": {sentiment: {score: 0}},
    "zPECQpZ9PnOZp14PRAx7Kw": {sentiment: {score: 0.2}},
    "CMglsj8f8qCxIO6FWgQ_tg": {sentiment: {score: 0.2}},
    "ASz3iV9mxIXisK7gkfMlRg": {sentiment: {score: 0.2}},
    "gsEvDqSUa8Goi2BBv5uljQ": {sentiment: {score: 0.2}},
    "rzypLROVsRZEpbToWoimfg": {sentiment: {score: 0.2}},
    "_P3WS6Zro-6FKqDZHL8ZlQ": {sentiment: {score: 0.2}},
    "qDh_EcM7JZAraqgpBd53AA": {sentiment: {score: 0.2}},
    "Df1nmQgNPAb3OKb5HBQe7A": {sentiment: {score: 0.3}},
    "qyz0Gxh03GJkvpRRA3DDNw": {sentiment: {score: 0}},
    "Q0SmfvWbkq8XbM7roHTwYw": {sentiment: {score: 0.2}},
    "jcj4gDaEz2Jz91Myr2NiQg": {sentiment: {score: 0.2}},
    "gBCjVphGsuLpnl4FcdH8kQ": {sentiment: {score: 0.2}},
    "QRFsy_-L9V6viyVY9Qvvhw": {sentiment: {score: 0.2}},

    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {article: {sentiment: {score: ___}}, sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
    // "___": {sentiment: {score: __}},
}

const idsToRemove = new Set([
    "QloTjcGK_1rTvyV_LGCeyA",
    "L2PgKRFKJs-chQZruIQSWA",
    "0qxSgC-fERk_ZcYDzJnWlA",
    "i-Jexmm8taDTaceN7yl2kw",
    "5AwX7D5J6H_qhClBeeXRpw",
    "S6FHHvPLMBZhu-ZqKcTT6g",
    "lvvXGjwBniPX6vVEwu-NlQ",
    "BDT-uQSmW2lx75OJgczdCg",
    "5vtFl6QimUqnQ-dlYzmN_A",
    "89QF7CAeQzpLiPAYWfr3zQ"
])

const cleanHaikus = (haikus) => {
    // Todo: Do more of this offline
    haikus = haikus.filter(h => h).filter(h => h.sentiment).filter(h => h.entities)
    haikus = haikus.filter(h => !idsToRemove.has(h.id))
    haikus = haikus.map(h => ({...h, content: naiveTitleCase(h.content)}))
    haikus = haikus.map(h => ((dataOverrides[h.id] ? defaultsDeep(dataOverrides[h.id], h) : h)))
    haikus = haikus.map(h => mapKeys(h, (v, k) => camelCase(k)))

    return haikus
}

const getAllHaikus = () => {
    return fetch("/data.json")
        .then(res => res.json())
        .then((haikus) => {
            return haikus

        })
}


export const loadAllHaikus = ({}, dispatch) => {
    dispatch({
        type: "LOAD_HAIKUS",
        haikus: cleanHaikus(initialHaikus)
    });
    return new Promise((resolve, reject) => {
        getAllHaikus()
            .then((haikus) => {

                dispatch({
                    type: "LOAD_HAIKUS",
                    haikus: cleanHaikus(haikus)
                });

                resolve(haikus)
            })
            .catch(() => {
                dispatch({
                    type: "LOAD_HAIKUS_ERROR"
                });

                reject()
            })
    })
};

export const buildIndices = ({}, dispatch) => {
    return new Promise((resolve, reject) => {

        dispatch({type: "BUILD_INDICES"});
        dispatch({type: "FILTER_HAIKUS"});
        resolve()
    })
};

export const filterHaikus = ({searchString, topics}, dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: "FILTER_HAIKUS",
            topics,
            searchString
        });
        resolve()
    })
};

// ([0-9a-zA-Z-_]{22}) ([0-9\.-]+) "$1": {article: \{sentiment\: \{score\: $2\}\}, sentiment\: \{score\: $2\}\},



